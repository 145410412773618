import React from "react";

import "styles/components/Home/PersonCard.style.sass";

interface PersonCardProp {
  imgURL: string;
  name: string;
  title: string;
}

const PersonCard = ({ imgURL, name, title }: PersonCardProp) => {
  return (
    <div className="person-card">
      <img src={imgURL} alt="boss" />
      <h3>{name}</h3>
      <p>{title}</p>
    </div>
  );
};

export default PersonCard;
