import { techPlaceholderImage } from "assets/images/blog";
import { BlogSocialMedia } from "data/socialMedia.info";
import {
  Author,
  AuthorPreview,
  BlogPost,
  BlogPreview,
  SocialMedia,
} from "interfaces/model/blog.model";
import { RichText, RichTextBlock } from "prismic-reactjs";

class SocialMediaDto {
  social_media: {
    data: {
      url: RichTextBlock[];
      type: string;
    };
  };

  static toSchema(dto: SocialMediaDto): SocialMedia {
    const data = dto.social_media.data;
    return {
      url: data.url,
      type: data.type as BlogSocialMedia,
    };
  }
}
class AuthorDto {
  id: string;
  data: {
    id: string;
    name: RichTextBlock[];
    social_media_list: SocialMediaDto[];
    profile_picture: RichTextBlock[];
  };

  static toSchema(dto: AuthorDto): Author {
    return {
      id: dto.id,
      name: RichText.asText(dto.data.name),
      socialMediaList: dto.data.social_media_list
        .filter((entry) => entry.social_media?.data)
        .map((entry) => SocialMediaDto.toSchema(entry)),
      profilePictureUrl: dto.data.profile_picture,
    };
  }
}

class CategoryDto {
  data: {
    category: RichTextBlock[];
  };
  static toSchema(dto: CategoryDto): string {
    return RichText.asText(dto.data.category);
  }
}
export class BlogPostDto {
  id: string;
  title: RichTextBlock[];
  author: AuthorDto;

  description: RichTextBlock[];
  content: RichTextBlock[];

  create_date: string;
  main_image: { url: string };

  category: CategoryDto;
  tag_list: string[];

  static toSchema(dto: BlogPostDto): BlogPost {
    return {
      id: dto.id,
      title: RichText.asText(dto.title),
      author: AuthorDto.toSchema(dto.author),
      description: dto.description,
      content: dto.content,
      createDate: dto.create_date,
      imageUrl: dto.main_image.url || techPlaceholderImage,
      category: CategoryDto.toSchema(dto.category),
      tagList: dto.tag_list,
    };
  }
}

// --- Preview Dtos
class AuthorPreviewDto {
  id: string;
  data: { id: string; name: RichTextBlock[] };

  static toSchema(dto: AuthorPreviewDto): AuthorPreview {
    return {
      id: dto.id,
      name: RichText.asText(dto.data.name),
    };
  }
}
export class BlogPostPreviewDto {
  id: string;
  title: RichTextBlock[];
  author: AuthorDto;

  create_date: string;
  main_image: { url: string };

  category: CategoryDto;

  static toSchema(dto: BlogPostPreviewDto): BlogPreview {
    return {
      id: dto.id,
      title: dto.title ? RichText.asText(dto.title) : "No title",
      author: dto.author
        ? AuthorPreviewDto.toSchema(dto.author)
        : { id: "unknown", name: "Apheleia" },
      createDate: dto.create_date,
      imageUrl: dto.main_image?.url || techPlaceholderImage,
      category: dto.category ? CategoryDto.toSchema(dto.category) : "Apheleia",
    };
  }
}
