interface EnvRunnerMap<T> {
  devFunc: (...args: any) => Promise<T> | T;
  prdFunc: (...args: any) => Promise<T> | T;
}

export async function executeBasedOnEnv<T>({
  devFunc,
  prdFunc,
}: EnvRunnerMap<T>): Promise<T> {
  let env = "prd";
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    env = process.env.REACT_APP_STAGE || "dev";
  }

  switch (env) {
    case "dev":
      return devFunc && (await devFunc());
    case "prd":
      return prdFunc && (await prdFunc());
    default:
      throw new Error("Unknown App stage, please check!");
  }
}
