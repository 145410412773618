export const locationList = ["default", "modal"] as const;
interface signupCopyProp {
  header: string;
  content: string;
  button: string;
  footer: string;
}

export const signupCopy: Record<typeof locationList[number], signupCopyProp> = {
  default: {
    header: "Sign up for early access!",
    content: "Join our beta testing team.",
    button: "Subscribe",
    footer: "No spam emails. Your info is safe!",
  },
  modal: {
    header: "Stockwise Early Access",
    content: "Sign up to get early access and stay updated on Stockwise news.",
    button: "Subscribe",
    footer: "No spam emails. Your info is safe!",
  },
};
