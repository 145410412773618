import { ReactNode } from "react";
import { Action, action } from "easy-peasy";

export interface ModalModel {
  content: null | ReactNode;
  setContent: Action<ModalModel, ReactNode | null>;
}

export const modal: ModalModel = {
  content: null,
  setContent: action((state, content) => {
    state.content = content;
  }),
};
