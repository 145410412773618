import { library } from "@fortawesome/fontawesome-svg-core";
import * as fab from "@fortawesome/free-brands-svg-icons";
import * as fas from "@fortawesome/free-solid-svg-icons";
// import * as fas from "@fortawesome/pro-solid-svg-icons";
import * as far from "@fortawesome/free-regular-svg-icons";

export const initializeIconList = () => {
  const iconList = [
    // * General
    far.faEnvelope,
    fas.faMapMarkerAlt,
    fas.faFilePdf,
    fas.faTimes,
    fas.faChevronRight,
    fas.faChevronLeft,

    // * Brands
    fab.faFacebook,
    fab.faTwitter,
    fab.faInstagram,
    fab.faGithub,
  ];
  // Set up icon libraries
  library.add(...iconList);
};
