import { thunk, Thunk } from "easy-peasy";
import firebase from "firebase/app";
export interface SubscriptionModel {
  subscribe: Thunk<SubscriptionModel, string, any, any, Promise<any>>;
}

export const subscription: SubscriptionModel = {
  subscribe: thunk(async (_, email) => {
    try {
      console.info("Sending new email for subscription");
      const subscribe = firebase
        .functions()
        .httpsCallable("addSendGridContact");

      await subscribe({ email });

      return null;
    } catch (error) {
      console.error(error);
      return error;
    }
  }),
};
