import { createStore, createTypedHooks } from "easy-peasy";
import { blogStore, BlogStoreModel } from "stores/blog";
import { subscription, SubscriptionModel } from "stores/subscription";
import { modal, ModalModel } from "./misc";

export interface StoreModel {
  modal: ModalModel;

  subscription: SubscriptionModel;
  blogStore: BlogStoreModel;
}

const model: StoreModel = {
  modal,

  subscription,
  blogStore,
};

const { useStoreState, useStoreActions } = createTypedHooks<StoreModel>();

export const store = createStore(model);
export { useStoreState, useStoreActions };
