import App from "App";
import { StoreProvider } from "easy-peasy";
import React from "react";
import ReactDOM from "react-dom";
// For Google Analytics
import ReactGA from "react-ga";
// For Hotjar Tracking
import { hotjar } from "react-hotjar";
import * as serviceWorker from "serviceWorker";
import { store } from "stores";
import { HOTJAR_ID, HOTJAR_SNIPPET_VERSION } from "./data/hotjarInfo";

ReactGA.initialize("UA-162091065-1");
ReactGA.pageview(window.location.pathname + window.location.search);

hotjar.initialize(HOTJAR_ID, HOTJAR_SNIPPET_VERSION);

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <App />
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
