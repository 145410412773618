import { Modal, SignupCard } from "components/Home";
import RouteManager from "components/managers/RouteManager";
import "firebase/analytics";
import firebase from "firebase/app";
import "firebase/functions";
import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useStoreActions } from "stores";
import { executeBasedOnEnv } from "utils/env.util";
import { initializeIconList } from "utils/iconInitializer";

initializeIconList();

if (firebase.apps.length <= 0) {
  firebase.initializeApp({
    apiKey: "AIzaSyDBnD6I47BKxLcWg-XKmCEN0Yx6HogdAVM",
    authDomain: "apheleia-web.firebaseapp.com",
    databaseURL: "https://apheleia-web.firebaseio.com",
    projectId: "apheleia-web",
    storageBucket: "apheleia-web.appspot.com",
    messagingSenderId: "440737792595",
    appId: "1:440737792595:web:faac87c2ca2c4b4982bca6",
    measurementId: "G-800WZ8V19Q",
  });
  firebase.analytics();
} else {
  firebase.app();
}

executeBasedOnEnv({
  devFunc: () => {
    console.info("In DEV environment");
    const BASE_HOST = "localhost";
    const FUNCTION_PORT = 5001;

    firebase.functions().useEmulator(BASE_HOST, FUNCTION_PORT);
  },
  prdFunc: () => {},
});

function App() {
  const { setContent } = useStoreActions((actions) => actions.modal);
  useEffect(() => {
    setContent(
      <SignupCard
        id="home-notify"
        location="modal"
        onClose={() => {
          setContent(null);
        }}
      />
    );
  }, [setContent]);

  return (
    <Router>
      <RouteManager />

      <ToastContainer />
      <Modal />
    </Router>
  );
}

export default App;
