import { redirectList, routeList } from "data/routeList";
import React, { Suspense, useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";

const RouteManager = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const history = useHistory();
  useEffect(() => {
    // Call analytics js from Segment
    // @ts-ignore
    analytics.page();
  }, [history.location]);

  return (
    <Suspense fallback={<div>Loading</div>}>
      <Switch>
        {routeList.map(({ path, Component }, index) => (
          <Route path={path} key={index}>
            <Component />
          </Route>
        ))}

        {redirectList.map(({ from, to }, key) => (
          <Redirect {...{ from, to, key }} />
        ))}
      </Switch>
    </Suspense>
  );
};

export default RouteManager;
