import { lazy, LazyExoticComponent } from "react";

const Home = lazy(() => import("pages/Home"));
const LegalPage = lazy(() => import("pages/LegalPage"));
const Product = lazy(() => import("pages/ProductPage"));
const Blog = lazy(() => import("pages/BlogPage"));
const Newsletter = lazy(() => import("pages/NewsletterPage"));

export interface RouteProp {
  path: string;
  Component: LazyExoticComponent<() => JSX.Element>;
  name: string;
  category: "header" | "footer";
}

export const routeMap = {
  legal: {
    path: "/legal",
    Component: LegalPage,
    name: "Legal",
    category: "footer",
  },
  products: {
    path: "/products",
    Component: Product,
    name: "Products",
    category: "header",
  },
  blog: {
    path: "/blog",
    Component: Blog,
    name: "Blog",
    category: "header",
  },
  home: {
    path: "/home",
    Component: Home,
    name: "Home",
    category: "header",
  },
  newsletter: {
    path: "/newsletter",
    Component: Newsletter,
    name: "Newsletter",
    category: "header",
  },
} as const;

export const blogPathMap = {
  post: routeMap.blog.path + "/post",
  category: routeMap.blog.path + "/category",
  author: routeMap.blog.path + "/author",
};

export const redirectList: { from: string; to: string }[] = [
  {
    from: "/",
    to: routeMap.home.path,
  },
];

export const routeList = Object.values(routeMap);
