import Icon from "components/commons/Icon";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useStoreActions } from "stores";
import "styles/components/commons/SignUpComponent.style.sass";

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
interface SignUpComponentProps {
  onSignUp?: () => void;
  buttonContent: React.ReactNode;
}
export const SignUpComponent = ({
  onSignUp,
  buttonContent,
}: SignUpComponentProps) => {
  const [email, setEmail] = useState("");
  const { subscribe } = useStoreActions((actions) => actions.subscription);

  const onSubscribeClick = async () => {
    if (!email) {
      toast("Please enter your email!");
      return;
    }

    if (!email.match(emailRegex)) {
      toast("Invalid email!");
      return;
    }

    const error = await subscribe(email);

    if (!error) {
      setEmail("");
      toast("Successfully subscribed!");
      onSignUp && onSignUp();
    } else {
      if (error.response?.data?.detail && error.response?.data?.title) {
        switch (error.response.data.title) {
          default:
            toast(`Failed to subscribe: ${error.response.data.detail}`);
        }
      } else {
        const unknownErrorStr = `Failed to subscribe: we are experiencing a little hiccup, please try to subscribed at another time.`;
        toast(unknownErrorStr);
        console.error(error);
      }
    }
  };

  return (
    <div className="signup-component">
      <div className="icon-container">
        <Icon className="icon" icon={["far", "envelope"]} />
      </div>

      <input
        name="field_name"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter your email"
      />
      <button onClick={onSubscribeClick}>{buttonContent}</button>
    </div>
  );
};
