import { modalPicture } from "assets/images/modal";
import React, { useEffect, useRef } from "react";
import { useClickAway } from "react-use";
import { useStoreActions, useStoreState } from "stores";
import "styles/components/Home/Modal.style.sass";
import { Portal } from "./Portal";

const Modal = () => {
  const content = useStoreState((state) => state.modal.content);
  const { setContent } = useStoreActions((actions) => actions.modal);

  const containerRef = useRef(null);
  useClickAway(containerRef, () => {
    setContent(null);
  });

  useEffect(() => {
    if (content) {
      document.body.style.position = "fixed";
      document.body.style.top = `-${window.scrollY}px`;
    } else {
      const scrollY = document.body.style.top;
      document.body.style.position = "";
      document.body.style.top = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }
  }, [content]);

  return content ? (
    <Portal containerId="root">
      <div className="modal">
        <div className="modal-content" ref={containerRef}>
          <img className="modal-picture" src={modalPicture} alt="modal" />
          {content}
          <button
            className="modal-content-close"
            onClick={() => setContent(null)}
          >
            <div />
            <div />
          </button>
        </div>
      </div>
    </Portal>
  ) : null;
};

export default Modal;
