import { SignUpComponent } from "components/commons/SignUpComponent";
import { signupCopy } from "data/signupContentMap";
import React from "react";
import "styles/components/Home/SignupCard.style.sass";

interface SignupProp {
  id: string;
  location: keyof typeof signupCopy;
  onClose?: Function;
}

export const Signup = ({ id, location, onClose }: SignupProp) => {
  const { header, content, button } = signupCopy[location];

  return (
    <div className="signup" id={id}>
      <div className="signup-content">
        <h3>{header}</h3>
        <p>{content}</p>
      </div>

      <SignUpComponent
        buttonContent={button}
        onSignUp={() => {
          onClose && onClose();
        }}
      />

      <p className="signup-spam">No spam emails. Your info is safe!</p>
    </div>
  );
};

export default Signup;
